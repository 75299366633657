<template>
  <div>
    <metadados-extensao-form
      ref="CategoriaForm"
      :id="idCategoria"
      :idNivelExtensao="idNivelExtensao"
      :somenteLeitura="somenteLeitura"
      :mostrarAtivacao="false"
      :tipoCadastro="objeto.label">
      <template slot="contentPreForm">
        <v-col cols="12">
          <v-select
            class="Form-text__invalid"
            id="tipo"
            name="tipo"
            item-text="nome"
            item-value="id"
            return-object
            clearable
            :items="tipos"
            :disabled="true"
            @input="carregarExtensoesPais"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :label="`${$tc('label.tipo', 1)} *`"
            v-model="objeto.tipo"
            :rules="[rules.required]">
          </v-select>
        </v-col>
      </template>
      <template slot="contentPosForm">
        <v-col cols="12">
          <v-autocomplete
            id="pai"
            name="pai"
            v-model="objeto.pai"
            item-text="nomExtensao"
            item-value="id"
            :label="`${$tc('label.pai', 1)} ${montarDescricaoTipoLabel()} ${maiorIndice == (objeto.tipo && objeto.tipo.indice ? objeto.tipo.indice : objeto.indice) ? '' : '*'}`"
            :items="pais"
            :no-data-text="$tc('message.nenhum_registro', 1)"
            :filter="filtroPai"
            :search-input.sync="buscaListaPais"
            :disabled="!!idCategoria || somenteLeitura"
            clearable
            return-object>
          </v-autocomplete>
        </v-col>
      </template>
      <template slot="button">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.native="cancelar" :disabled="somenteLeitura">{{ $t('label.cancelar') }}</v-btn>
          <v-btn @click.native="abrirDialogSalvar" color="primary" :disabled="somenteLeitura">{{ $t('label.salvar') }}</v-btn>
        </v-card-actions>
      </template>
    </metadados-extensao-form>
    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', {text: $tc('label.' + objeto.label, 1)})"
      :persistent="true"
      @agree="salvar">
    </confirm>
  </div>
</template>

<script type="text/javascript">
import MetadadosExtensaoForm from '@/shared-components/metadados-extensao/MetadadosExtensaoForm';
import Confirm from '@/shared-components/dialog/Confirm';

export default {
  name: 'Hierarquia',
  data() {
    return {
      idCategoria: null,
      idNivelExtensao: 2,
      maiorIndice: null,
      menorIndice: null,
      pais: [],
      objeto: {},
      objetoLabelSelecionado: {},
      tipos: [],
      buscaListaPais: null,
      extensaoResources: this.$api.extensao(this.$resource),
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredPai: value => this.tipoValido() ? true : // eslint-disable-line
          (!this.tipoValido() && !value ? this.$t('message.campo_obrigatorio') : true), // eslint-disable-line
      },
    };
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Confirm,
    MetadadosExtensaoForm,
  },
  watch: {
    buscaListaPais(val) {
      if (this.timeoutProduto) {
        window.clearTimeout(this.timeoutProduto);
      }
      this.timeoutProduto = window.setTimeout(() => {
        this.buscarPaiAutocomplete(val);
      }, 500);
    },
  },
  methods: {
    abrirDialogSalvar() {
      if (!this.$refs.CategoriaForm.validarFormulario()) return;
      this.$refs.confirmDialog.open();
    },
    buscarMetadadosLabels() {
      this.carregarExtensoesPais(this.objeto);
    },
    cancelar() {
      this.limparFormulario();
      this.$emit('HIERARQUIA_CANCELAR');
    },
    carregarExtensoesPais(objeto) {
      if (!objeto) return;
      this.objetoLabelSelecionado = objeto;
      const filtroDesAtributo = 'indiceLabel';
      const filtroDesAtributoValor = objeto.indice + 1;
      const params = {
        ...null,
        idNivelExtensao: this.idNivelExtensao,
        tamanhoPagina: 30,
        limit: 30,
        filtroDesAtributo,
        filtroDesAtributoValor,
      };

      if (this.idCategoria != null) {
        params.limit = 200;
        params.tamanhoPagina = 200;
      }

      this.extensaoResources.listarAtivos(params).then((resultado) => {
        this.pais = resultado.data.resposta;
      });
    },
    filtroPai(item, queryText, itemText) {
      const codPai = item.idExterno;
      const searchText = queryText.toLowerCase();

      return codPai.toLowerCase().indexOf(searchText) > -1
        || itemText.toLowerCase().indexOf(searchText) > -1;
    },
    buscarPaiAutocomplete(busca = null) {
      const filtroDesAtributo = 'indiceLabel';
      const filtroDesAtributoValor = this.objetoLabelSelecionado.indice + 1;
      const params = {
        ...null,
        idNivelExtensao: this.idNivelExtensao,
        autocomplete: busca,
        limit: 30,
        tamanhoPagina: 30,
        page: 0,
        filtroDesAtributo,
        filtroDesAtributoValor,
      };

      if (this.idCategoria != null) {
        params.limit = 200;
        params.tamanhoPagina = 200;
      }

      this.extensaoResources.listarAtivos(params).then((resultado) => {
        this.pais = resultado.data.resposta;
      }, (err) => {
        this.$error(this, err);
      });
    },
    limparFormulario() {
      this.$refs.CategoriaForm.limparFormulario();
      this.objetoLabelSelecionado = {};
      this.pais = [];
      this.idCategoria = null;
    },
    montarDesAtributos() {
      const desAtributos = {};

      if (this.objetoHasProperties(this.objetoLabelSelecionado)) {
        return desAtributos;
      }

      desAtributos.nomeLabel = this.objetoLabelSelecionado.nome;
      desAtributos.indiceLabel = this.objetoLabelSelecionado.indice;
      return desAtributos;
    },
    montarDescricaoTipoLabel() {
      if (this.pais && this.pais.length > 0) {
        return this.pais[0].desAtributos && this.pais[0].desAtributos.nomeLabel
          ? `(${this.pais[0].desAtributos.nomeLabel})` : '';
      }
      return '';
    },
    objetoHasProperties(objeto) {
      if (typeof (objeto) === 'number') {
        return false;
      }

      return Object.keys(objeto).length === 0
        && objeto.constructor === Object;
    },
    prepararObjeto() {
      const objetoComponente = this.$refs.CategoriaForm.prepararObjeto();
      this.objeto.pai = this.objeto.pai
        ? this.objeto.pai.id || this.objeto.pai : null;
      this.objeto.id = objetoComponente.id;
      this.objeto.idExterno = objetoComponente.idExterno;
      this.objeto.nomExtensao = objetoComponente.nomExtensao;
      this.objeto.indAtivo = objetoComponente.indAtivo;
      this.objeto.idNivelExtensao = this.idNivelExtensao;
      this.objeto.desAtributos = this.montarDesAtributos();
      return this.objeto;
    },
    salvar() {
      this.extensaoResources.gravarAutoRelacionamento(this.prepararObjeto())
        .then((response) => {
          this.$emit('HIERARQUIA_INSERIDA', response.data, this.objeto.label);
          this.limparFormulario();
        }, (err) => {
          this.$error(this, err);
        });
    },
    setarTipos(tipos) {
      if (!this.tipos.length) {
        this.tipos = [...tipos];
      }
    },
    setarObjeto(objeto) {
      if (!this.objeto || !this.objeto.id) {
        this.objeto = { ...objeto };
      }
    },
    tipoValido() {
      if (this.objeto) {
        return this.maiorIndice === (this.objeto.tipo && this.objeto.tipo.indice
          ? this.objeto.tipo.indice : this.objeto.indice);
      }
      return true;
    },
  },
};
</script>
<style>
  .Categoria__ContentTable {
    width: 100% !important;
  }
</style>
